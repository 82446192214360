export const formatPrice = (number) => {
    const n = Number(number).toFixed(3);
    const array = n.toString().split('.');
    const integer = Number(array[0]).toLocaleString();
    const decimal = array[1] ? `.${array[1]}` : '';
    return `${integer}${decimal}`;
}

export const APMServices = {
    VERIF: '5f9bd92938b86958b8afc57a'
}

export const APMCategories = {
    DEMANDE_INFORMATION: '5f9bd99638b86958b8afc582',
    MODIFICATION_DONNEES_PERSONNELLES: '5f9bd97138b86958b8afc57c',
    REPONSE_LITIGE: '5f9bd9aa38b86958b8afc588'
}

export const getTitleDate = (date = new Date()) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    return `${day}.${month}.${year}`;
}