import React from 'react';
import axios from 'axios';
import { withRouter } from 'next/router';
import MultiSelect from '@khanacademy/react-multi-select';
import { FileDrop } from 'react-file-drop';
import { getAPIUrl, jwtManager } from 'aqlrc';
import { Router } from '../routes'
import ModuleAPMNew from '../modules/apm-aquilav2/ModuleAPMNew'
import { APMCategories } from 'lib/utils';

class APMNew extends ModuleAPMNew {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            services: [],
            selectedServices: [],
            servicesUsers: [],
            selectedServicesUsers: [],
            priorities: [],
            sources: [],
            ticket: {
                assignTo    : { services: [], service: [], user: [] },
                files       : [],
                follow_mail : true,
                key         : {},
                source      : {}
            },
            toast: {
                bg: '#2e8b57',
                message: '',
                show: false
            },
            onSave: false
        };
        this.fileInputRef = React.createRef();
        this.formTicket = React.createRef();
        this.mounted = true;
    }

    async componentDidMount() {
        let { url, urledit, urllogin } = this.props;
        let { ticket } = this.state;
        let user            = jwtManager.getUser();
        if (!user) {
            if (!urllogin) { urllogin = '/'; }
            return Router.pushRoute(urllogin);
        }
        if (!urledit) { urledit = '/apmedit'; }

        const jwt = jwtManager.get();
        if (jwt) {
            axios.defaults.headers.common.Authorization = jwt;
        }

        const resUser = await axios.post(`${getAPIUrl()}v2/user/${user._id}`, {
            PostBody : {
                structure : { details: 1, apm_services: 1 }
            }
        });
        user          = resUser.data;
        this.shouldSendToLtg()

        // Redirection vers le ticket si il en existe un avec le même objet
        if (url && url.query && url.query.title) {
            const resTickets = await axios.post(`${getAPIUrl()}v1/APM/ticket`, {"q": url.query.title});
            if (resTickets.data.list[0]) {
                return Router.pushRoute(`${urledit}?id=${resTickets.data.list.sort((a, b) => a.code - b.code)[0]._id}`);
            }
        }

        const resCategories = await axios.post(`${getAPIUrl()}v1/APM/category`, { filter: {} }); // Récupération des catégories
        const resPriorities = await axios.get(`${getAPIUrl()}v1/APM/genericList/priority`); // Récupération des priorités
        const resSources    = await axios.get(`${getAPIUrl()}v1/APM/genericList/source`); // Récupération des sources

        this.setState({
            categories : resCategories.data.categories.sort((a, b) => a.name.localeCompare(b.name)),
            priorities : resPriorities.data,
            sources    : resSources.data,
            user,
            ticket: {...this.state.ticket, STEB3: this.props.url.query.company}

        }, () => {
            if (url && url.query && url.query.category) {
                this.onCategoryChange({ target: { value: url.query.category } });
            }

        });
    }

    onCategoryChange = (e) => {
        const { categories, ticket } = this.state;
        const category_id = e.target.value;

        // On remet à zéro la liste des services dans un 1er temps (pour désélectionner ceux qui l'étaient éventuellement)
        // Dans un 2ème temps, on la remplit
        this.setState({ services: [] }, () => {
            if (categories.find(c => c._id === category_id)) {
                ticket.category = category_id;
                const services = categories.find(c => c._id === category_id).services.sort((a, b) => a.name.localeCompare(b.name));
                ticket.assignTo.services = services;
                const service = categories.find(c => c._id === category_id).service;
                this.setState({ ticket, services, servicesUsers: [] }, () => { this.onAssignService([service.id]) })
            } else {
                ticket.assignTo.services = [];
                this.setState({ ticket, services: [], servicesUsers: [] })
            }
        })
    }

    shouldSendToLtg = () => {
        const isModification = (this.state.ticket?.category || this?.props?.url?.query?.category) === '5f9bd97138b86958b8afc57c' ;
        const isInformation = (this.state.ticket?.category || this?.props?.url?.query?.category) === '5f9bd99638b86958b8afc582';
        return isModification || isInformation
    }

    onAssignService = async (selectedServices) => {
        const { url } = this.props;
        const { selectedServicesUsers, services, ticket } = this.state;
        const assign_services = [];
        if (selectedServices.length) {
            for (let id of selectedServices) {
                if (services.find(s => s._id === id)) {
                    assign_services.push({ id, name: services.find(s => s._id === id).name})
                }
            }
        }
        ticket.assignTo.service = assign_services;

        // On récupère les utilisateurs liés au(x) service(s) sélectionné(s)
        const resServicesUsers = await axios.post(`${getAPIUrl()}v1/APM/service/users`, {service: { $in: selectedServices }});

        // On met à jour la liste des utilisateurs cochés en fonction du retour de l'API ci-dessus
        if (selectedServicesUsers.length) {
            for (let i = selectedServicesUsers.length - 1; i >= 0; i--) {
                if (!resServicesUsers.data.list.find(u => u._id === selectedServicesUsers[i])) {
                    selectedServicesUsers.splice(i, 1);
                }
            }
        }

        this.setState({ selectedServices: selectedServices, selectedServicesUsers, servicesUsers: resServicesUsers.data.list, ticket }, () => {
            let array = [];
            if (url.query.follow) {
                if (resServicesUsers.data.list.find(u => u.email === url.query.follow)) {
                    const id = resServicesUsers.data.list.find(u => u.email === url.query.follow)._id;
                    if(!array.includes(id)) array.push(id);
                }
            }
            const srvLtg = resServicesUsers.data.list.find(u => u.email === 'srvfr.ltg@ciffreobona.fr');
            if (srvLtg && this.shouldSendToLtg() && !url.query.follow) {
                if(!array.includes(srvLtg._id)) array.push(srvLtg._id);
            } else if (srvLtg && url.query.follow !== 'srvfr.ltg@ciffreobona.fr') {
                array = array.filter(el => el !== srvLtg._id)
            }
            if(array.length === 0 && this.props.url.query.category !== APMCategories.MODIFICATION_DONNEES_PERSONNELLES) array = [srvLtg._id];
            this.onAssignUser(array);
        })
    }

    onTargetFileClick = () => {
        this.fileInputRef.current.click()
    }

    save = async (e) => {
        e.preventDefault();

        let { urllist } = this.props;
        const { sources, ticket } = this.state;
        this.setState({ onSave: true });

        // Adressé à
        if (!ticket.assignTo.service.length) {
            this.showSimpleToast('warn', 'Veuillez assigner au moins 1 service à ce ticket !');
            this.setState({ onSave: false });
            return;
        }

        // si follow vide
        if (!ticket.assignTo.user || ticket.assignTo.user.length === 0) {

            this.showSimpleToast('warn', 'Le ticket n\'est suivi par personne !');
            this.setState({ onSave: false });
            return;
        }

        // Priorité
        ticket.priority = this.formTicket.priority.value;

        // Date d'application
        if (this.formTicket.deadline.value) {
            if (isNaN(Date.parse(this.formTicket.deadline.value))) {
                this.showSimpleToast('warn', 'La date d\'application du ticket est invalide !');
                this.setState({ onSave: false });
                return;
            } else {
                ticket.deadline = new Date(this.formTicket.deadline.value);
            }
        }

        // Source du ticket
        const source_id = this.formTicket.source.value; // Source du ticket
        if (sources.find(s => s._id === source_id)) {
            ticket.source = sources.find(s => s._id === source_id);
        } else {
            ticket.source = "";
        }
        if (this.formTicket.sourceDate.value) {
            if (isNaN(Date.parse(this.formTicket.sourceDate.value))) {
                this.showSimpleToast('warn', 'La date de source du ticket est invalide !');
                this.setState({ onSave: false });
                return;
            } else {
                ticket.source.date = new Date(this.formTicket.sourceDate.value); // Date de la source du ticket
            }
        }

        // Traitement de la demande par mail
        ticket.follow_mail = this.formTicket.follow_mail.checked;

        // Envoi un mail au client pour lui indiquer que le ticket a été créé (car ticket creer par CBO via la connexion au client du backoffice)
        ticket.force_notify = this.formTicket.force_notify.checked;

        // Objet de la demande
        ticket.title = this.formTicket.title.value;

        // Description de la demande
        ticket.description = this.formTicket.description.value;

        try {
            await axios.put(`${getAPIUrl()}v1/APM/ticket/${false}`, ticket);
            if (!urllist) { urllist = '/apmlist'; }
            Router.pushRoute(urllist);
        } catch(err) {
            if (err.data && err.data.message) {
                this.showSimpleToast('error', err.data.message);
            } else {
                this.showSimpleToast('error', 'Erreur lors de la création du ticket ! Veuillez rafraîchir la page et réessayer');
            }
            this.setState({ onSave: false });
        }
        this.setState({onSave: false});
    }

    render() {
        let { labelticket } = this.props;
        const { url }       = this.props;
        const {
            categories, onSave, priorities, selectedServices, services, selectedServicesUsers, servicesUsers, sources, ticket, toast, user
        } = this.state;
        if (!labelticket) { labelticket = 'ticket'; }
        return (
            <>
                <div className="apm-toast" style={{ backgroundColor: toast.bg }} hidden={!toast.show}><p>{toast.message}</p></div>
                <form ref={(form) => this.formTicket = form} className="apm_new_container" onSubmit={this.save}>
                    <button type="button" onClick={() => Router.back()}>
                        Retour
                    </button>
                    <h2>NOUVEAU {labelticket}</h2>
                    <div className="form-box category">
                        <label htmlFor="category" className="required">Catégorie</label>
                        <div style={{ width: '40%' }}>
                            <select id="category" name="category" className="input-box" value={ticket.category} onChange={this.onCategoryChange} required disabled={url?.query?.category}>
                                <option value="" />
                                {categories.map((category) => <option key={`category_${category._id}`} value={category._id}>{category.name}</option>)}
                            </select>
                        </div>
                    </div>

                    <div className="form-box assignTo">
                        <label htmlFor="assignToService" className="required">Adressé à</label>
                        <div style={{ width: '40%' }}>
                            <MultiSelect
                                options={services.map((s) => ({ label: s.name, value: s._id }))}
                                selected={selectedServices}
                                disableSearch="true"
                                overrideStrings={{ selectSomeItems: 'Sélectionner des services', selectAll: 'Sélectionner tout', allItemsAreSelected: 'Tous les services sont sélectionnés' }}
                                onSelectedChanged={(selected) => this.onAssignService(selected)}
                                disabled={!(user && user.details && user.details.isSuperUserAPM)}
                            />
                            <MultiSelect
                                options={servicesUsers.map((s) => ({ label: s.fullname, value: s._id }))}
                                selected={selectedServicesUsers}
                                disableSearch="true"
                                overrideStrings={{ selectSomeItems: 'Sélectionner des utilisateurs', selectAll: 'Sélectionner tout', allItemsAreSelected: 'Tous les utilisateurs sont sélectionnés' }}
                                onSelectedChanged={(selected) => this.onAssignUser(selected)}
                                disabled={!(user && user.details && user.details.isSuperUserAPM)}
                            />
                        </div>
                    </div>

                    <div className="form-box priority">
                        <label htmlFor="assignToService" className="required">Priorité</label>
                        <div style={{ width: '40%' }}>
                            <select name="priority" id="priority" className="input-box">
                                {priorities.map((p) => <option key={`priority_${p._id}`} value={p._id}>{p.name}</option>)}
                            </select>
                        </div>
                    </div>

                    <div className="form-box deadline">
                        <label htmlFor="deadline">Date d'application</label>
                        <div style={{ width: '40%' }}>
                            <input name="deadline" className="input-box" id="deadline" type="date" />
                        </div>
                    </div>

                    <div className="form-box source">
                        <label htmlFor="source" style={{ verticalAlign: 'middle' }}>Source</label>
                        <div style={{ width: '10%', verticalAlign: 'middle' }}>
                            <select name="source" className="input-box" id="source">
                                {sources.map((s) => <option key={`source_${s._id}`} value={s._id}>{s.name}</option>)}
                            </select>
                        </div>
                        <div style={{ marginLeft: '20px', width: '10%', verticalAlign: 'middle' }}>
                            <input type="date" id="sourceDate" name="sourceDate" className="input-box" />
                        </div>
                    </div>

                    <div className="form-box title">
                        <label htmlFor="title" className="required">Objet</label>
                        <div style={{ width: '83%' }}>
                            <input type="text" className="input-box" name="title" id="title" value={url?.query?.title ? url.query.title : ''} disabled={url?.query?.title} readOnly required />
                        </div>
                    </div>

                    <div className="form-box description">
                        <label style={{ verticalAlign: 'top' }} htmlFor="description" className="required">Décrivez votre demande</label>
                        <div style={{ width: '83%' }}>
                            <textarea type="text" name="description" id="description" className="input-box" rows="3" required />
                        </div>
                    </div>

                    <div className="form-box uploads">
                        <label style={{ verticalAlign: 'top' }} htmlFor="file">Pièces jointes</label>
                        <div className="files" onClick={this.onTargetFileClick}>
                            <FileDrop onDrop={(files) => this.uploadFile({ target: { files } })}>
                                <img src="/medias/attachment.png" /><br />
                                Cliquez dans cette zone pour afficher vos fichiers
                            </FileDrop>
                            <input ref={this.fileInputRef} type="file" id="file" name="file" onChange={(e) => this.uploadFile(e)} style={{ display: 'none' }} />
                        </div>
                        <div className="uploaded">
                            {ticket.files.map((file, index) => (
                                <div key={`file_${file.name}`} className="file" style={{ display: 'flex' }}>
                                    <div style={{ width: '90%', display: 'inline-block' }}>
                                        <a href={`/${file.url}`} download={file.initialName} target="_blank">{file.initialName}</a>
                                    </div>
                                    <div
                                        style={{
                                            width : '10%', display : 'inline-block', textAlign : 'center', cursor : 'pointer'
                                        }} onClick={() => this.removeTicketFile(index)}
                                    >
                                        X
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="submit" style={{ textAlign: 'right', marginTop: '30px', paddingRight: '10px' }}>

                        <label htmlFor="follow_mail" style={{ width: 'inherit', marginLeft: '1%' }}>Voulez vous un suivi du traitement de la demande par mail ?

                            <input id="follow_mail" defaultChecked type="checkbox" name="follow_mail" />
                        </label>


                    <label htmlFor="force_notify" style={{ width: 'inherit', marginLeft: '1%' }}>Envoyer un email de confirmation de création de ticket

                        <input id="force_notify" defaultChecked={(url?.query?.category === APMCategories.REPONSE_LITIGE)} type="checkbox" name="force_notify" />
                    </label>
                        <button type="submit" className="ajax-load btn btn-medium" style={{ width: '200px', marginLeft: '20px', display: (onSave ? 'none' : 'visible') }}>
                            Valider
                        </button>
                    </div>
                </form>
                <style jsx>{`
                    .apm-toast {
                        width: 300px;
                        padding: 10px;
                        z-index: 9999;
                        position: fixed;
                        top: 20px;
                        right: 20px;
                        border-radius: 5px;
                    }

                    .apm-toast p {
                        color: #fff;
                        font-size: 16px;
                        margin: 0;
                    }

                    .apm_new_container h2 { text-transform: uppercase; }
                    .apm_new_container button { padding: 10px; border: 1px solid; outline: none; cursor: pointer; }
                    .apm_new_container .form-box { display: flex; margin-bottom: 10px; line-height: 30px; }
                    
                    .apm_new_container .form-box > label { width: 15%; text-align: right; padding-right: 10px; }

                    .apm_new_container label.required:after {
                        content: "*";
                        color: red;
                        padding-left: 3px;
                    }

                    .apm_new_container input[type=text],
                    .apm_new_container input[type=date],
                    .apm_new_container select {
                        height: 34px;
                        border-radius: 4px;
                        border: 1px solid rgb(179, 179, 179);
                    }

                    .apm_new_container textarea { height: 100px; border-radius: 4px; border: 1px solid rgb(179, 179, 179); }
                    .apm_new_container .container-select-multiple { margin-left: 1%; }

                    .apm_new_container .input-box { width: 100%; }
                    .apm_new_container .files { width: 150px; height: 70px; border: 2px dashed; margin-right: 10px; cursor: pointer; }
                    .apm_new_container .uploaded { width: 30%; border-left: 1px solid }
                    .apm_new_container .uploaded .file { padding: 5px; }
                    .apm_new_container .uploaded .file:nth-child(odd) { background-color: #ececec; }
                `}</style>
                <style jsx global>{`
                    .apm_new_container .files .file-drop { height: 70px; line-height: normal; display: flex; align-items: center; justify-content: center; text-align: center; }
                `}</style>
            </>
        );
    }
}

export default withRouter(APMNew)
