import React from 'react';
import axios from 'axios';
import moment from 'moment';
import MultiSelect from '@khanacademy/react-multi-select';
import ModalR from 'react-responsive-modal';
import { getAPIUrl, jwtManager } from 'aqlrc';
import { Router } from '../../routes';

class ModuleAPMEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            formNewNote : '',
            idTicket: '',
            open         : false,
            priorities: [],
            rights       : { edit: false, changeStatus: false },
            servicesUsers: [],
            sources: [],
            statuses: [],
            ticket       : {
                user     : {},
                category : { id: { services: [] } },
                assignTo : {
                    service : [],
                    user    : []
                },
                files    : [],
                source   : {},
                priority : {},
                notes    : [],
                history  : [],
                status   : {},
            },
            toast: {
                bg: '#2e8b57',
                message: '',
                show: false
            },
            users    : []
        };
        this.fileInputRef = React.createRef();
        this.formTicket = React.createRef();
        this.mounted = true;
    }

    async componentDidMount () {
        let { urllist, urllogin } = this.props;
        const { rights } = this.state;

        let user = jwtManager.getUser();
        if (!user) {
            if (!urllogin) { urllogin = '/'; }
            return Router.pushRoute(urllogin);
        }

        if (!urllist) { urllist = '/apmlist'; }

        const jwt = jwtManager.get();
        if (jwt) {
            axios.defaults.headers.common.Authorization = jwt;
        }

        const resUser = await axios.post(`${getAPIUrl()}v2/user/${user._id}`, {
            PostBody : {
                structure : { details: 1, apm_services: 1 }
            }
        });
        user = resUser.data;

        const [ , idTicket ] = window.location.search.split('id=');
        if (!idTicket) {
            this.showSimpleToast('error', 'Une erreur est survenue lors de la récupération des données !');
            return Router.pushRoute(urllist);
        }

        try {
            const resTicket = await axios.get(`${getAPIUrl()}v1/APM/ticket/${idTicket}`); // Récupération du ticket (avec en paramètre "id" de l'URL)
            const resCategories = await axios.post(`${getAPIUrl()}v1/APM/category`, {filter: {}}); // Récupération des catégories
            const resPriorities = await axios.get(`${getAPIUrl()}v1/APM/genericList/priority`); // Récupération des priorités
            const resSources = await axios.get(`${getAPIUrl()}v1/APM/genericList/source`); // Récupération des sources
            const resStatuses = await axios.get(`${getAPIUrl()}v1/APM/genericList/status`); // Récupération des statuts

            // Formatage de la date d'application
            const ticket = resTicket.data;
            moment.locale('fr');
            if (ticket.deadline) {
                ticket.deadline = moment(ticket.deadline).format('YYYY-MM-DD');
            }

            // Utilisateurs assignables
            const services_ids = [...ticket.assignTo.service].map(s => s.id);
            const resServicesUsers = await axios.post(`${getAPIUrl()}v1/APM/service/users`, {service: { $in: services_ids }});

            // Profil de suivi
            let users = [];
            if (ticket.user) {
                const resFollowers = await axios.post(`${getAPIUrl()}v1/APM/ticket/followers`, {_id: ticket._id});
                users = resFollowers.data;
            }

            // Droits
            const hasMainCategoryService = user.apm_services.find(service => service.id === ticket.category.id.service.id);
            if (hasMainCategoryService) {
                rights.changeStatus = true;
            }
            if ((user.details && user.details.isSuperUserAPM) || ticket.status.name !== "Clos") {
                rights.edit = true;
                rights.changeStatus = true;
            }

            this.setState({
                categories: resCategories.data.categories,
                idTicket,
                priorities: resPriorities.data,
                rights,
                servicesUsers: resServicesUsers.data.list,
                sources: resSources.data,
                statuses: resStatuses.data,
                ticket,
                users
            });
        } catch (err) {
            console.error('APM : ', err);
            this.showSimpleToast('error', 'Une erreur est survenue lors de la récupération des données !');
            return Router.pushRoute(urllist);
        }
    }

    onChangeStatus = (e) => {
        const { ticket, statuses } = this.state;
        ticket.status = { id: e.target.value, name: statuses.find(s => s._id === e.target.value).name };
        this.setState({ ticket });
    }

    onChangeFollower = (e) => {
        const { ticket, users } = this.state;
        const { id, fullname } = users.find(user => user._id === e.target.value);
        ticket.user = { id, name: fullname };
        this.setState({ ticket });
    }

    onChangePriority = (e) => {
        const { ticket, priorities } = this.state;
        ticket.priority = { id: e.target.value, name: priorities.find(s => s._id === e.target.value).name };
        this.setState({ ticket });
    }

    onAssignService = async (selectedServices) => {
        const { ticket } = this.state;
        const assign_services = [];
        if (selectedServices.length) {
            for (let id of selectedServices) {
                assign_services.push({ id, name: ticket.category.id.services.find(s => s._id === id).name})
            }
        }
        ticket.assignTo.service = assign_services;
        
        // On récupère les utilisateurs liés au(x) service(s) sélectionné(s)
        const resServicesUsers = await axios.post(`${getAPIUrl()}v1/APM/service/users`, {service: { $in: selectedServices }});

        // On met à jour la liste des utilisateurs cochés en fonction du retour de l'API ci-dessus
        if (ticket.assignTo.user.length) {
            for (let i = ticket.assignTo.user.length - 1; i >= 0; i--) {
                if (!resServicesUsers.data.list.find(u => u._id === ticket.assignTo.user[i].id)) {
                    ticket.assignTo.user.splice(i, 1);
                }
            }
        }

        this.setState({ servicesUsers: resServicesUsers.data.list, ticket })
    }

    onAssignUser = async (selectedUsers) => {
        const { ticket, servicesUsers } = this.state;
        const assign_users = [];
        if (selectedUsers.length) {
            for (let id of selectedUsers) {
                assign_users.push({ id, name: servicesUsers.find(s => s._id === id).fullname})
            }
        }
        ticket.assignTo.user = assign_users;

        this.setState({ ticket })
    }

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onChangeComment = (event) => {
        this.setState({ formNewNote: event.target.value });
    }

    onConfirmModal = async () => {
        const { formNewNote, idTicket } = this.state;
        try {
            const { ticket } = this.state;
            const response = await axios.put(`${getAPIUrl()}v1/APM/ticket/${idTicket}/note`, { information: formNewNote }); // Poste un échange
            const resData = response.data;
            if (!ticket.notes) {
                ticket.notes = [];
            }
            ticket.notes.push({ date: resData.newNote.date, information: resData.newNote.information, user: resData.newNote.user });
            if (!ticket.history) {
                ticket.history = [];
            }
            ticket.history = resData.history;
            this.setState({ ticket, open: false, formNewNote: '' });
            this.showSimpleToast('success', 'Note ajoutée !');
        } catch(err) {
            console.error('APM : ', err);
            this.showSimpleToast('error', 'Erreur lors de l\'ajout de la note !');
        }
    }

    uploadFile = async (e) => {
        const { ticket } = this.state;
        const selectedFile = e.target.files[0];
        e.target.value = null; // Reset du champ file sinon l'upload à la suite ne fonctionne pas
        const data = new FormData();
        data.append("_id", ticket._id);
        data.append("file", selectedFile, selectedFile.name);

        try {
            const resFiles = await axios.post(`${getAPIUrl()}v1/APM/ticket/file`, data);
            ticket.files.push(resFiles.data);
            this.showSimpleToast('success', 'Fichier ajouté !');
            this.setState({ ticket });
        } catch(err) {
            console.error('APM : ', err);
            this.showSimpleToast('error', 'Erreur lors de l\'envoi du fichier !');
        }
    }

    removeTicketFile = async (index) => {
        const { ticket } = this.state;
        try {
            if (ticket.files[index].url.startsWith('/APM')) {
                await axios.post(`${getAPIUrl()}v1/APM/ticket/file/delete`, { index, ticket }); // Pour les fichiers sauvegardés
            } else {
                await axios.delete(`${getAPIUrl()}v1/APM/ticket/file/${ticket.files[index].fileName}`); // Pour les fichiers temporaires
            }
            ticket.files.splice(index, 1);
            this.setState({ ticket });
        } catch(err) {
            console.error('APM : ', err);
            this.showSimpleToast('error', 'Erreur lors de la suppression du fichier !');
        }
    }

    save = async (withoutMail) => {
        let { urllist } = this.props;
        const { ticket } = this.state;

        const ticketUpdate = {
            _id         : ticket._id,
            category    : ticket.category.id,
            status      : ticket.status.id,
            user        : ticket.user,
            assignTo    : ticket.assignTo,
            priority    : ticket.priority.id,
            title       : this.formTicket.title.value,
            description : this.formTicket.description.value
        };

        if (this.formTicket.deadline.value) {
            if (isNaN(Date.parse(this.formTicket.deadline.value))) {
                this.showSimpleToast('warn', 'La date d\'application du ticket est invalide !');
                return;
            } else {
                ticketUpdate.deadline = new Date(this.formTicket.deadline.value);
            }
        }

        if (ticket.files) {
            ticketUpdate.files = ticket.files.filter(file => file.fileName === file.url); // On ne met que les nouveaux fichiers uploadés
        }

        try {
            await axios.put(`${getAPIUrl()}v1/APM/ticket/${withoutMail}`, ticketUpdate);
            this.showSimpleToast('success', 'Sauvegarde effectuée !');
            if (!urllist) { urllist = '/apmlist'; }
            Router.pushRoute(urllist);
        } catch (err) {
            if(err.response && err.response.data && err.response.data.message) {
                this.showSimpleToast('error', err.response.data.message);
            } else {
                console.error('APM : ', err);
                this.showSimpleToast('error', 'Erreur lors de la sauvegarde !');
            }
        }
    }

    exportTicket = async () => {
        const { ticket } = this.state;
        try {
            const response = await axios.get(`${getAPIUrl()}v1/APM/ticket/export/${ticket._id}`);
            const filename = response.headers["content-disposition"];
            const ticketPDF = document.createElement("a");
            ticketPDF.href = `data:application/pdf;base64,${response.data}`;
            ticketPDF.download = filename.slice(filename.indexOf("filename") + 10, -1);
            document.body.appendChild(ticketPDF);
            ticketPDF.click();
            ticketPDF.remove();
        } catch(err) {
            console.error('APM : ', err);
            this.showSimpleToast("Impossible d'exporter le ticket !");
        }
    }

    formatDate = (date) => {
        moment.locale('fr');
        return moment(date).format('L');
    }

    onTargetFileClick = () => {
        this.fileInputRef.current.click()
    }

    showSimpleToast = (type, message) => {
        const { toast } = this.state;
        if (type === 'success') {
            toast.bg = '#2e8b57';
        } else if (type === 'warn') {
            toast.bg = '#ffa500';
        } else {
            toast.bg = '#c92c2c';
        }
        toast.message = message;
        toast.show = true;
        this.setState({ toast }, () => { 
            setTimeout(() => { 
                if (this.mounted) {
                    toast.show = false;
                    this.setState({ toast });
                }
            }, 5000);
        })
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        let { labelticket } = this.props;
        const { open, priorities, rights, servicesUsers, statuses, ticket, toast, users } = this.state;
        if (!labelticket) { labelticket = 'ticket'; }
        return (
            <>
                <div className="apm-toast" style={{ backgroundColor: toast.bg }} hidden={!toast.show}><p>{toast.message}</p></div>
                <div className="apm_edit_container">
                    <button type="button" onClick={() => Router.back() }>
                        Retour
                    </button>
                    <div className="header">
                        <h2>EDITION D'UN {labelticket}</h2>
                        <div>
                            {rights.edit && <button type="button" className="save" onClick={() => this.save(false)}>Mettre à jour et envoyer</button>}
                            {rights.edit && <button type="button" className="saveWithout" onClick={() => this.save(true)}>Mettre à jour sans envoi</button>}
                            {rights.edit && <button type="button" className="export" onClick={() => this.exportTicket()}>Exporter en PDF</button>}
                        </div>
                    </div>
                    <div className="edit">
                        <form ref={form => this.formTicket = form}>
                            <div className="row">
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="4%" className="id">ID</th>
                                            <th width="33%" className="keys">Clefs</th>
                                            <th width="33%" className="category">Catégorie</th>
                                            <th width="15%" className="status">Statut</th>
                                            <th width="5%" className="createdAt">Date de soumission</th>
                                            <th width="5%" colSpan="2" className="updatedAt">Dernière mise à jour</th>
                                            <th width="5%" className="deadline">Date d'application</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="id">{ticket.code}</td>
                                            <td className="keys"></td>
                                            <td className="category">{ticket.category.name}</td>
                                            <td className="status">
                                                <select name="status" value={ticket.status.id} style={{ width: '100%' }} disabled={rights.changeStatus === false} onChange={this.onChangeStatus}>
                                                    {statuses.map(status => <option key={`status_${status._id}`} value={status._id}>{status.name}</option>)}
                                                </select>
                                            </td>
                                            <td className="createdAt">{this.formatDate(ticket.createdAt)}</td>
                                            <td colSpan="2" className="updatedAt">{this.formatDate(ticket.updatedAt)}</td>
                                            <td className="deadline">
                                                <input name="deadline" className="input-box" id="deadline" type="date" defaultValue={ticket.deadline} style={{ width: '100%' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="follower">
                                                Profil de suivi : <select name="follower" value={ticket.user.id} onChange={this.onChangeFollower}>
                                                                    {users.map(user => <option key={`user_${user._id}`} value={user._id}>{user.fullname}</option>)}
                                                                </select>
                                            </td>
                                            <td colSpan="2" className="assignTo">
                                                <div>Assigné à :</div>
                                                <div>
                                                    <MultiSelect
                                                        options={ticket.category.id.services.sort((a, b) => a.name.localeCompare(b.name)).map(s => { return {label: s.name, value: s._id } })}
                                                        selected={ticket.assignTo.service.map(s => s.id)}
                                                        disableSearch="true"
                                                        overrideStrings={{selectSomeItems: "Sélectionner des services", selectAll: "Sélectionner tout", allItemsAreSelected: "Tous les services sont sélectionnés"}}
                                                        onSelectedChanged={selected => this.onAssignService(selected)}
                                                    />
                                                    <MultiSelect
                                                        options={servicesUsers.map(s => { return {label: s.fullname, value: s._id } })}
                                                        selected={ticket.assignTo.user.map(u => u.id)}
                                                        disableSearch="true"
                                                        overrideStrings={{selectSomeItems: "Sélectionner des utilisateurs", selectAll: "Sélectionner tout", allItemsAreSelected: "Tous les utilisateurs sont sélectionnés"}}
                                                        onSelectedChanged={selected => this.onAssignUser(selected)}
                                                    />
                                                </div>
                                            </td>
                                            <td colSpan="2" className="source">Source : {(ticket.source.name || ticket.source.date) ? `${ticket.source.name} ${ticket.source.date ? this.formatDate(ticket.source.date) : ''}` : 'aucune'}</td>
                                            <td colSpan="2" className="priority">
                                                Priorité : <select name="priority" value={ticket.priority.id} onChange={this.onChangePriority}>
                                                                {priorities.map(priority => <option key={`priority_${priority._id}`} value={priority._id}>{priority.name}</option>)}
                                                            </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row title">
                                <label htmlFor="title" className="bold">Objet</label>
                                <input type="text" id="title" name="title" defaultValue={ticket.title} />
                            </div>
                            <hr />
                            <div className="row description">
                                <label htmlFor="description" className="bold">
                                    Décrivez votre demande
                                </label>
                                <textarea id="description" rows="6" name="description" defaultValue={ticket.description}>{ticket.description}</textarea>
                            </div>
                        </form>
                    </div>

                    <div className="notes">
                        <div className="row">
                            <h3>
                                Nos échanges
                                <button type="button" onClick={() => this.setState({open: true})} style={{ float: "right" }}>AJOUTER</button>
                            </h3>
                            <table>
                                <tbody>
                                    {ticket.notes.map(note => (
                                        <tr key={`note_${note._id}`} className="activity">
                                            <td className="user">
                                                <div>
                                                    <p>{note.user.name}</p>
                                                    <p>{this.formatDate(note.date)}</p>
                                                </div>
                                            </td>
                                            <td className="message">
                                                <pre style={{fontFamily: "inherit", whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html: note.information}} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <h3 style={{ display: ticket.notes.length ? 'flex' : 'none', justifyContent: 'flex-end' }}>
                                <button type="button" onClick={() => this.setState({open: true})}>AJOUTER</button>
                            </h3>
                            <ModalR animationDuration={0} classNames={{modal: 'apm_popup__container', overlay: 'popup active'}} open={open} onClose={this.onCloseModal} center styles={{ modal: { width: '30%' } }}>
                                <h2 className="popup__title">Nouvel échange</h2>
                                <div className="popup__outer"><p>Saisir le contenu de l'échange</p></div>

                                <div className="popup__body">
                                    <form>
                                        <textarea name="note" value={this.state.formNewNote} rows="5" onChange={e => this.onChangeComment(e)} required aria-label="échange" style={{ width: '100%' }} />
                                    </form>
                                </div>
                                <div className="popup__foot">
                                    <button type="button" className="btn" onClick={this.onCloseModal}>Annuler</button>
                                    <button type="button" id="btn-confirm-comment" className="btn" onClick={this.onConfirmModal} hidden={this.state.formNewNote === ''}>Confirmer</button>
                                </div>
                            </ModalR>
                        </div>
                    </div>

                    <div className="documents">
                        <div className="row">
                            <h3>Documents</h3>
                            <div className="files_container">
                                <div className="files" onClick={this.onTargetFileClick}>
                                    <div className="click">Cliquez dans cette zone pour afficher vos fichiers</div>
                                    <input ref={this.fileInputRef} type="file" id="file" name="file" onChange={(e) => this.uploadFile(e)} style={{ display: 'none' }} />
                                </div>
                                <div className="uploaded">
                                    {ticket.files.map((file, index) => (
                                        <div key={`file_${file.fileName}`} className="file" style={{ display: "flex" }}>
                                            <div style={{width: "90%", display: "inline-block"}}>
                                                <a href={file.url.startsWith('/') ? file.url : `/${file.url}`} download={file.initialName} target="_blank">{file.initialName}</a>
                                            </div>
                                            <div style={{width: "10%", display: "inline-block", textAlign: "center", cursor: 'pointer'}} onClick={() => this.removeTicketFile(index)}>
                                                X
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="history">
                        <div className="row">
                            <h3>Historique</h3>
                            <table className="table table-clickable">
                                <thead>
                                    <tr>
                                        <th width="10%">Date de modification</th>
                                        <th width="15%">Nom d'utilisateur</th>
                                        <th width="35%">Champ</th>
                                        <th width="40%">Changement</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticket.history.map(action => (
                                        <tr key={`history_${action._id}`}>
                                            <td>{this.formatDate(action.date)}</td>
                                            <td>{action.user.name}</td>
                                            <td dangerouslySetInnerHTML={{__html: action.field}} />
                                            <td dangerouslySetInnerHTML={{__html: action.change}} />
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <style jsx>{`
                    .apm-toast { width: 300px; padding: 10px; z-index: 9999; position: fixed; top: 20px; right: 20px; border-radius: 5px; }
                    .apm-toast p { color: #fff; font-size: 16px; margin: 0; }

                    .apm_edit_container { background-color: #ececec; padding: 10px; }
                    .apm_edit_container button { padding: 10px; border: 1px solid; outline: none; cursor: pointer; }
                    .apm_edit_container button + button { margin-left: 10px; }
                    .apm_edit_container .header { display: flex; align-items: center; justify-content: space-between; }
                    .apm_edit_container .header h2 { text-transform: uppercase; }
                    .apm_edit_container .edit,
                    .apm_edit_container .notes,
                    .apm_edit_container .documents,
                    .apm_edit_container .history { background-color: white; padding: 10px; margin-bottom: 10px; }
                    .apm_edit_container .edit .row { margin-bottom: 20px; }
                    .apm_edit_container .edit table { width: 100%; border-collapse: collapse; }
                    .apm_edit_container .edit table th { background-color: #dddddd; text-align: left; padding: 10px; border: 1px #ffffff solid;}
                    .apm_edit_container .edit table td { background-color: #eeeeee; text-align: left; padding: 10px; border: 1px #ffffff solid;}
                    .apm_edit_container .edit table .select-multiple { height: 100px;}
                    .apm_edit_container input[type=text],
                    .apm_edit_container input[type=date],
                    .apm_edit_container select {
                        height: 34px;
                        border-radius: 4px;
                        border: 1px solid rgb(179, 179, 179);
                    }
                    .apm_edit_container textarea {
                        border-radius: 4px;
                        border: 1px solid rgb(179, 179, 179);
                    }
                    .apm_edit_container .edit .row > label { display: inline-block; width: 15%; vertical-align: top; }
                    .apm_edit_container .edit .row > input, .apm_edit_container .edit .row > textarea { width: 75%; }

                    .apm_edit_container .notes table { border-collapse: collapse; width: 100%; }
                    .apm_edit_container .notes td { padding: 10px; background-color: #eeeeee; border: 1px solid white; }
                    .apm_edit_container .notes .user { width: 15%; }

                    .apm_edit_container .documents .row .files_container { display: flex; }
                    .apm_edit_container .documents .row .files { width: 150px; height: 50px; border: 2px dashed; padding: 10px; margin-right: 10px; display: flex; align-items: center; justify-content: center; text-align: center; cursor: pointer; }
                    .apm_edit_container .documents .row .files .click { font-style: italic; }
                    .apm_edit_container .documents .row .uploaded { width: 30%; border-left: 1px solid; }
                    .apm_edit_container .documents .row .uploaded .file { padding: 5px; }
                    .apm_edit_container .documents .row .uploaded .file:nth-child(odd) { background-color: #ececec; }

                    .apm_edit_container .history table { width: 100%; border-collapse: collapse; }
                    .apm_edit_container .history table th { background-color: #dddddd; text-align: left; padding: 10px; border: 1px #ffffff solid;}
                    .apm_edit_container .history table td { background-color: #eeeeee; text-align: left; padding: 10px; border: 1px #ffffff solid;}
                `}</style>
            </>
        );
    }
}

export default ModuleAPMEdit;