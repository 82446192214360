import React from 'react';
import axios from 'axios';
import ReactPagination from 'react-js-pagination';
import { getAPIUrl, jwtManager } from 'aqlrc';
import { Router } from '../routes';
import ModuleAPMList from '../modules/apm-aquilav2/ModuleAPMList';

class APMList extends ModuleAPMList {
    constructor(props) {
        super(props);
        const itemsPerPages = 15;
        this.state          = {
            count : 0,
            itemsPerPages,
            query : {
                page      : 1,
                limit     : itemsPerPages,
                type      : 2,
                sort      : 'updatedAt',
                direction : 'DESC',
                status    : {
                    Nouveau                : true,
                    Affecté                : true,
                    'Demande informations' : true,
                    'En cours'             : true,
                    Rejeté                 : true
                }
            },
            tickets : [],
            toast   : {
                bg      : '#2e8b57',
                message : '',
                show    : false
            },
            status : [],
            sort   : { field: 'updatedAt', direction: 'DESC' },
            user   : {}
        };
        this.mounted        = true;
    }

    onChangeWithCompany = async (e) => {
        this.onChangeType(e.target.checked ? 5 : 2)
    }

    async componentDidMount () {
        let { urllogin } = this.props;
        let { query } = this.state;
        
        let user = jwtManager.getUser();
        if (!user) {
            if (!urllogin) { urllogin = '/'; }
            return Router.pushRoute(urllogin);
        }

        const jwt = jwtManager.get();
        if (jwt) {
            axios.defaults.headers.common.Authorization = jwt;
        }

        const resUser = await axios.post(`${getAPIUrl()}v2/user/${user._id}`, {
            PostBody : {
                structure : { details: 1, attributes: 1 }
            }
        });
        user = resUser.data;

        if (window.localStorage.getItem('query')) {
            query = JSON.parse(window.localStorage.getItem('query')); // Récup de la query cachée dans le local storage
        }
        try {
            const resTickets = await axios.post(`${getAPIUrl()}v1/APM/ticket`, query);
            const resStatus = await axios.get(`${getAPIUrl()}v1/APM/genericList/status`);
            await this.filterTickets();
            
            this.setState({ status: resStatus.data, query, user, tickets: resTickets.data.list })
            window.localStorage.setItem('query', JSON.stringify(query));
        } catch (err) {
            console.error('APM : ', err);
        }
    }

    sortByNotes = async (e) => {
        let {query} = this.state;
        if (window.localStorage.getItem('query')) {
            query = JSON.parse(window.localStorage.getItem('query')); // Récup de la query cachée dans le local storage
        }
        query.sort = 'notes.length'
        query.direction = query.direction === 'DESC' ? 'ASC' : 'DESC'
        this.setState({query}, async () => await this.filterTickets())
    }

    reset = () => {
        const { itemsPerPages } = this.state;
        window.localStorage.removeItem('query');
        const query = {
            "page": 1,
            "deadline": "",
            "q": "",
            "limit": itemsPerPages,
            "type": 2,
            "sort": "updatedAt",
            "direction": "DESC",
            "status": {
                "Nouveau": true,
                "Affecté": true,
                "Demande informations": true,
                "En cours": true,
                "Rejeté": true
            }
        }
        this.setState({ query }, this.filterTickets);
    }

    render() {
        let { labelticket } = this.props;
        const {
            count, itemsPerPages, query, status, tickets, toast, user
        } = this.state;
        if (!labelticket) { labelticket = 'ticket'; }
        return (
            <>
                <div className="apm-toast" style={{ backgroundColor: toast.bg }} hidden={!toast.show}><p>{toast.message}</p></div>
                <div className="apm_container">
                    <div className="apm_list_container">
                        <div className="apm_top">
                            {
                                user.attributes && user.attributes.find(a => a.code === 'cbo') && user.attributes.find(a => a.code === 'cbo').value && (
                                    <div className="apm_filter_types">
                                        <button type="button" className={`btn${query.type === 0 ? ' active' : ''}`} onClick={() => this.onChangeType(0)}>A traiter</button>
                                        <button type="button" className={`btn${query.type === 1 ? ' active' : ''}`} onClick={() => this.onChangeType(1)}>Mes services</button>
                                        <button type="button" className={`btn${query.type === 2 ? ' active' : ''}`} onClick={() => this.onChangeType(2)}>Mes Demandes</button>
                                        <button type="button" className={`btn${query.type === 3 ? ' active' : ''}`} onClick={() => this.onChangeType(3)}>Service demande</button>
                                        <button type="button" className={`btn${query.type === 4 ? ' active' : ''}`} onClick={() => this.onChangeType(4)}>Tout</button>
                                    </div>
                                )
                            }

                            <div className="apm_buttons_utilities">
                                <button type="button" className="btn_export" onClick={this.exportTickets}>Exporter pour Excel</button>
                                <button type="button" className="btn_new" onClick={this.goToNewTicket}>Nouveau</button>
                            </div>
                        </div>
                        <h2>LISTE DES {labelticket}S</h2>
                        <div className="apm_filter_status">
                            { (!user.attributes || !user.attributes.find(a => a.code === 'cbo') || !user.attributes.find(a => a.code === 'cbo').value) && (
                                <label style={{flex: 1}}>
                                    <input type="checkbox" name="TicketWithCompany" onChange={this.onChangeWithCompany} checked={query.type === 5} />
                                    Tickets de mes collaborateurs
                                </label>
                            )}
                            {
                                status.map((s, index) => (
                                    <label key={`status_${s._id}`} className={`status_${index}`}>
                                        <input type="checkbox" name={`status_${s.name}`} checked={query.status[s.name]} onChange={this.onChangeStatus} />
                                        {s.name}
                                    </label>
                                ))
                            }
                        </div>
                        <table className="apm_tickets_list">
                            <thead>
                                <tr>
                                    <th className="code" style={{ maxWidth: '10%', cursor: 'pointer' }} onClick={() => this.onSortTickets('code')}>
                                        Code
                                    </th>
                                    <th className="category" style={{ maxWidth: '15%', cursor: 'pointer' }} onClick={() => this.onSortTickets('category.name')}>
                                        Catégorie
                                    </th>
                                    <th className="com" style={{ maxWidth: '5%', cursor: 'pointer' }} title="Commentaires" onClick={() => this.sortByNotes()}>
                                        C
                                    </th>
                                    <th className="nbFiles" style={{ maxWidth: '5%' }} title="Nombre de documents">
                                        P
                                    </th>
                                    <th className="deadline" style={{ cursor: 'pointer' }} onClick={() => this.onSortTickets('deadline')}>
                                        Priorité
                                    </th>
                                    <th className="status" style={{ maxWidth: '10%', cursor: 'pointer' }} onClick={() => this.onSortTickets('status.id.order')}>
                                        Statut
                                    </th>
                                    <th className="updatedAt" style={{ maxWidth: '5%', cursor: 'pointer' }} onClick={() => this.onSortTickets('updatedAt')}>
                                        Màj
                                    </th>
                                    <th className="STEB3" style={{ minWidth: '70px', cursor: 'pointer' }} onClick={() => this.onSortTickets('STEB3')}>
                                        Société
                                    </th>
                                    <th className="title" style={{ minWidth: '200px', cursor: 'pointer' }} onClick={() => this.onSortTickets('title')}>
                                        Objet
                                    </th>
                                    <th className="follower" style={{ cursor: 'pointer' }} onClick={() => this.onSortTickets('user.name')}>
                                        Profil suivi
                                    </th>
                                    <th className="assignTo" style={{ cursor: 'pointer' }} onClick={() => this.onSortTickets('assignTo.user.name')}>
                                        Profil affecté
                                    </th>
                                    <th className="lastUpdate" style={{ maxWidth: '10%' }}>
                                        Dernière Modification
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tickets.map((ticket) => {
                                        const assignToUsers = [];
                                        ticket.assignTo.user.map((u) => assignToUsers.push(u.name));
                                        return (
                                            <tr key={`ticket_${ticket._id}`} onClick={() => this.editTicket(ticket._id)}>
                                                <td className="code" style={{ textAlign: 'center' }}>{ticket.code}</td>
                                                <td className="category">{ticket.category}</td>
                                                <td className="com" style={{ textAlign: 'center' }}>{ticket.nbr_notes}</td>
                                                <td className="nbFiles" style={{ textAlign: 'center' }}>{ticket.nbrDocument}</td>
                                                <td className="deadline">{ticket.priority === 'Date butoir' ? this.formatDate(ticket.deadline) : ticket.priority }</td>
                                                <td className="status">
                                                    <span className={`ticket_status_${status.findIndex((s) => s.name === ticket.status)}`} style={{ color: 'black', padding: '2px' }}>{ticket.status}</span>
                                                </td>
                                                <td className="updatedAt" style={{ textAlign: 'center' }}>{this.formatDate(ticket.updatedAt)}</td>
                                                <td className="company">{ticket.companyLabel}</td>
                                                <td className="title">{ticket.title}</td>
                                                <td className="follower">{ticket.user.name}</td>
                                                <td className="assignTo">{assignToUsers.join(', ')}</td>
                                                <td className="lastUpdate">{ticket.history[ticket.history.length - 1].user.name}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="apm_paging" hidden={count <= itemsPerPages}>
                            <ReactPagination
                                hideDisabled
                                activePage={query.page}
                                itemsCountPerPage={itemsPerPages}
                                totalItemsCount={count}
                                pageRangeDisplayed={10}
                                onChange={(page) => this.onChangePage(page)}
                                activeClass="current"
                                prevPageText={<span>&lt;</span>}
                                nextPageText={<span>&gt;</span>}
                                firstPageText={<span>&lt;&lt;</span>}
                                lastPageText={<span>&gt;&gt;</span>}
                            />
                        </div>
                    </div>
                    <div className="apm_filters_container">
                        <h2 className="apm_filters_title">FILTRES</h2>
                        <form onSubmit={this.filterTickets}>
                            <div className="form-box fts">
                                <label>Rechercher</label>
                                <small><i>(code, titre, description, catégorie, profils)</i></small>
                                <div>
                                    <input type="text" name="search" className="input-box" value={query.q} onChange={this.onChangeSearch} />
                                </div>
                            </div>
                            <div className="form-box priority">
                                <label>Priorité</label>
                                <div>
                                    <label>
                                        <input id="priority" name="priority" type="checkbox" className="input-box" checked={query.priority} onChange={this.onChangePriority} /> Immédiat
                                    </label>
                                    <br />
                                    <input id="deadline" name="deadline" type="date" className="input-box" value={query.deadline} onChange={this.onChangeDeadline} />
                                </div>
                            </div>
                            <div>
                                <button type="submit">Filtrer</button> <button type="button" onClick={this.reset}>Réinitialiser par défaut</button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

export default APMList;
