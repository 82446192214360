import React from 'react';
import axios from 'axios';
import { NSContext, NSToast, logoutUser } from 'aqlrc';
import { Link, Router } from '../routes';

class SidebarAccount extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    logout = async () => {
        // Déconnexion de l'utilisateur
        try {
            await logoutUser();
            delete axios.defaults.headers.common.Authorization;
            Router.pushRoute('home');
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                NSToast.error(err.response.data.message);
            } else {
                NSToast.error('common:error_occured');
                console.error(err);
            }
        }
    }

    render() {
        const { props } = this.context;
        if (!props) { return null; }
        const { companyName } = props;
        return (
            <div id="MenuGauche">
                <p>ESPACE FOURNISSEUR</p>
                <ul>
                    <li className="rs"><b>{companyName}</b></li>
                    <li>&nbsp;</li>
                    <li>
                        <Link route="accountHome">
                            <a>Accueil</a>
                        </Link>
                    </li>
                    <li>
                        <Link route="accountProvider">
                            <a>Mon Compte Fournisseur</a>
                        </Link>
                    </li>
                    <li>
                        <Link route="invoiceSearch">
                            <a>Recherche par facture</a>
                        </Link>
                    </li>
                    <li>
                        <Link route="invoiceAmountSearch">
                            <a>Recherche par montant TTC</a>
                        </Link>
                    </li>
                    <li>
                        <Link route="invoiceDispute">
                            <a>Factures en litiges</a>
                        </Link>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                        <Link route="account">
                            <a>Mon Compte Web</a>
                        </Link>
                    </li>
                    <li>
                        <Link route="staticI18n" params={{ _slug: 'apmlist'}}>
                            <a>Nos échanges</a>
                        </Link>
                    </li>
                    <li>&nbsp;</li>
                    <li><a href="#" onClick={this.logout}>Quitter</a></li>
                </ul>
            </div>
        );
    }

    static contextType = NSContext;
}

export default SidebarAccount;
