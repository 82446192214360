import { NSContext, NSLogin, NSToast, authUser } from 'aqlrc';
import React from 'react';
import { withI18next } from '../lib/withI18n';
import { Router } from 'routes';

class Login extends NSLogin {
    componentDidMount = () => {
        const { user } = this.context.props;
        if (user) {
            Router.pushRoute('accountHome');
        }
    }
    
    loginRequest = async (email, password) => {
        const { routerLang } = this.context.state;

        // Authentification
        try {
            await authUser(email, password);
            Router.pushRoute('accountHome', { lang: routerLang });
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                NSToast.error(err.response.data.message);
            } else {
                NSToast.error('common:error_occured');
                console.error(err);
            }
        }
    };

    render() {
        const {
            emailResetPassword, step, user
        } = this.state;
        return (
            <>
            {
                step === 1 ? (
                    <form onSubmit={(e) => this.handleResetSubmit(e)} method="post">
                        <div id="ident">
                            <div className="login">Identifiant <input type="text" name="email" value={emailResetPassword} onChange={(e) => this.handleResetPassword(e)} /></div>
                            <div className="valid">
                                <button type="button" onClick={ () => this.setState({ step: 0 }) }>Retour</button> <button type="submit">Valider</button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <form onSubmit={e => this.handleLoginSubmit(e)} method="post">
                        <div id="ident">
                            <div className="login">
                                Identifiant<br/>
                                <input type="text" id="email_login" name="email" value={user.email} />
                            </div>
                            <br/>
                            <div className="mp">
                                Mot de passe<br/>
                                <input type="password" id="password_login" name="mdp" value={user.password} />
                            </div>
                            <div className="valid">
                                <button type="submit">Valider</button>
                            </div>
                            <p><a href="#" onClick={ () => this.setState({ step: 1 }) }>Mot de passe oublié ?</a></p>
                        </div>
                    </form>
                )
            }
            </>
        );
    }

    static contextType = NSContext;
}

export default withI18next(['login'])(Login);
