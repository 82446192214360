import React from 'react';
import { _NSconfig } from 'aqlrc'; // Import depuis AQLRC de la config par défaut
import APMNew from './APMNew';
import APMList from './APMList';
import APMEdit from './APMEdit';
import SidebarAccount from './SidebarAccount';
import Login from './Login';

// On surcharge la config par défaut de AQLRC si besoin
// A noter que <Link> et <CMS> sont déjà gérés directement dans le composant CMS, il faut utiliser respectivement "ns-link" et "ns-cms"
// A garder dans l'ordre alphabétique en fonction du nom du composant SVP
export default {
    ..._NSconfig,
    'ns-apmlist' : <APMList />,
    'ns-apmnew'  : <APMNew />,
    'ns-apmedit' : <APMEdit />,
    'ns-login'   : <Login />,
    'ns-sidebar' : <SidebarAccount />
};
