/*
 * Retourne un objet contenant toutes les traductions AQLRC + celles du thème fusionnées
 */

const { assets, mergeRecursive } = require('aqlrc');

const namespaces = ['account', 'addresses', 'cart', 'category', 'delivery', 'common', 'login', 'payment', 'product-card', 'product', 'resetpass', 'static', 'success'];

const theme_assets = {};
theme_assets['fr'] = {};
for (const ns in namespaces) {
    try {
        theme_assets['fr'][namespaces[ns]] = require(`assets/i18n/fr/${namespaces[ns]}.json`);
    } catch (e) {}
}
module.exports = { assets: mergeRecursive(assets, theme_assets), namespaces };
