import React from 'react';
import axios from 'axios';
import moment from "moment";
import ReactPagination from 'react-js-pagination';
import { getAPIUrl, jwtManager } from 'aqlrc';
import { Router } from '../../routes';

class ModuleAPMList extends React.Component {
    constructor(props) {
        super(props);
        const itemsPerPages = 15;
        this.state = {
            count: 0,
            itemsPerPages,
            query: {
                "page": 1,
                "limit": itemsPerPages,
                "type": 0,
                "sort": "updatedAt",
                "direction": "DESC",
                "status": {
                    "Nouveau": true,
                    "Affecté": true,
                    "Demande informations": true,
                    "En cours": true,
                    "Rejeté": true
                }
            },
            tickets: [],
            toast: {
                bg: '#2e8b57',
                message: '',
                show: false
            },
            status: [],
            sort: { field: 'updatedAt', direction: 'DESC' },
            user: {}
        };
        this.mounted = true;
    }

    async componentDidMount () {
        let { urllogin } = this.props;
        let { query } = this.state;
        
        let user = jwtManager.getUser();
        if (!user) {
            if (!urllogin) { urllogin = '/'; }
            return Router.pushRoute(urllogin);
        }

        const jwt = jwtManager.get();
        if (jwt) {
            axios.defaults.headers.common.Authorization = jwt;
        }

        const resUser = await axios.post(`${getAPIUrl()}v2/user/${user._id}`, {
            PostBody : {
                structure : { details: 1 }
            }
        });
        user = resUser.data;

        if (window.localStorage.getItem('query')) {
            query = JSON.parse(window.localStorage.getItem('query')); // Récup de la query cachée dans le local storage
        }
        try {
            const resTickets = await axios.post(`${getAPIUrl()}v1/APM/ticket`, query);
            const resStatus = await axios.get(`${getAPIUrl()}v1/APM/genericList/status`);
            
            this.setState({ count: resTickets.data.count, query, tickets: resTickets.data.list, status: resStatus.data, user })
        } catch (err) {
            console.error('APM : ', err);
        }
    }

    onChangeType = (type) => {
        const { query } = this.state;
        query.page = 1;
        query.type = type;
        this.setState({ query }, this.filterTickets);
    }

    onChangeStatus = (e) => {
        const { query } = this.state;
        query.page = 1;
        query.status[e.target.name.split('status_')[1]] = e.target.checked;
        this.setState({ query }, this.filterTickets);
    }

    onChangePage = (page) => {
        const { query } = this.state;
        query.page = page;
        this.setState({ query }, this.filterTickets);
    }

    onSortTickets = async (field) => {
        const { query } = this.state;
        query.page = 1;
        if (query.sort === field) {
            query.direction = (query.direction === 'ASC' ? 'DESC' : 'ASC');
        } else {
            query.direction = 'ASC';
        }
        query.sort = field;
        this.setState({ query }, this.filterTickets);
    }

    onChangeSearch = (e) => {
        const { query } = this.state;
        query.q = e.target.value;
        this.setState({ query });
    }

    onChangePriority = (e) => {
        const { query } = this.state;
        query.priority = e.target.checked;
        this.setState({ query });
    }

    onChangeDeadline = (e) => {
        const {  query} = this.state;
        query.deadline = e.target.value;
        this.setState({ query });
    }

    filterTickets = async (e) => {
        if (e) { e.preventDefault(); }
        const { query } = this.state;
        try {
            const resTickets = await axios.post(`${getAPIUrl()}v1/APM/ticket`, query);
            window.localStorage.setItem('query', JSON.stringify(query)); // On cache la query dans le local storage
            this.setState({ count: resTickets.data.count, tickets: resTickets.data.list }, () => window.scrollTo({ left: 0, top: document.querySelector('.apm_list_container').offsetTop, behavior: 'smooth' }))
        } catch (err) {
            console.error('APM : ', err);
            this.showSimpleToast('error', 'Impossible de charger les tickets !');
        }
    }

    exportTickets = async () => {
        const { query, tickets } = this.state;
        if (!tickets.length) return this.showSimpleToast('warn', 'Aucun ticket à exporter !');
        try {
            const exportTicketsRes = await axios.post(`${getAPIUrl()}v1/APM/ticket/export`, query);
            const filename = exportTicketsRes.headers['content-disposition'];
            const ticketCSV = document.createElement("a");
            ticketCSV.href = `data:text/csv;base64,${exportTicketsRes.data}`;
            ticketCSV.download = filename.slice(filename.indexOf('filename') + 10, -1);
            document.body.appendChild(ticketCSV);
            ticketCSV.click();
            ticketCSV.remove();
        } catch(err) {
            console.error('APM : ', err);
            this.showSimpleToast('error', 'Impossible d\'exporter les tickets !');
        }
    }

    goToNewTicket = () => {
        let { urlnew } = this.props;
        if (!urlnew) { urlnew = '/apmnew'; }
        Router.pushRoute(urlnew);
    }

    editTicket = (id) => {
        let { urledit } = this.props;
        if (!urledit) { urledit = '/apmedit'; }
        Router.pushRoute(`${urledit}?id=${id}`);
    }

    reset = () => {
        const { itemsPerPages } = this.state;
        window.localStorage.removeItem('query');
        const query = {
            "page": 1,
            "deadline": "",
            "q": "",
            "limit": itemsPerPages,
            "type": 0,
            "sort": "updatedAt",
            "direction": "DESC",
            "status": {
                "Nouveau": true,
                "Affecté": true,
                "Demande informations": true,
                "En cours": true,
                "Rejeté": true
            }
        }
        this.setState({ query }, this.filterTickets);
    }

    formatDate = (date) => {
        moment.locale('fr');
        return moment(date).format('L');
    }

    showSimpleToast = (type, message) => {
        const { toast } = this.state;
        if (type === 'success') {
            toast.bg = '#2e8b57';
        } else if (type === 'warn') {
            toast.bg = '#ffa500';
        } else {
            toast.bg = '#c92c2c';
        }
        toast.message = message;
        toast.show = true;
        this.setState({ toast }, () => { 
            setTimeout(() => {
                if (this.mounted) {
                    toast.show = false;
                    this.setState({ toast });
                }
            }, 5000);
        })
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        let { labelticket } = this.props;
        const { count, itemsPerPages, query, status, tickets, toast } = this.state;
        if (!labelticket) { labelticket = 'ticket'; }
        return (
            <>
                <div className="apm-toast" style={{ backgroundColor: toast.bg }} hidden={!toast.show}><p>{toast.message}</p></div>
                <div className="apm_container">
                    <div className="apm_list_container">
                        <div className="apm_top">
                            <div className="apm_filter_types">
                                <button type="button" className={`btn${query.type === 0 ? ' active' : ''}`} onClick={() => this.onChangeType(0)}>A traiter</button>
                                <button type="button" className={`btn${query.type === 1 ? ' active' : ''}`} onClick={() => this.onChangeType(1)}>Mes services</button>
                                <button type="button" className={`btn${query.type === 2 ? ' active' : ''}`} onClick={() => this.onChangeType(2)}>Mes Demandes</button>
                                <button type="button" className={`btn${query.type === 3 ? ' active' : ''}`} onClick={() => this.onChangeType(3)}>Service demande</button>
                                <button type="button" className={`btn${query.type === 4 ? ' active' : ''}`} onClick={() => this.onChangeType(4)}>Tout</button>
                            </div>
                            <div className="apm_buttons_utilities">
                                <button type="button" className="btn_export" onClick={this.exportTickets}>Exporter pour Excel</button>
                                <button type="button" className="btn_new" onClick={this.goToNewTicket}>Nouveau</button>
                            </div>
                        </div>
                        <h2>LISTE DES {labelticket}S</h2>
                        <div className="apm_filter_status">
                        {
                            status.map((s, index) => {
                                return (
                                    <label key={`status_${s._id}`} className={`status_${index}`}>
                                        <input type="checkbox" name={`status_${s.name}`} checked={query.status[s.name]} onChange={this.onChangeStatus} />
                                        {s.name}
                                    </label>
                                )
                            })
                        }
                        </div>
                        <table className="apm_tickets_list">
                            <thead>
                                <tr>
                                    <th className="code" style={{ maxWidth: '10%', cursor: 'pointer' }} onClick={() => this.onSortTickets('code')}>
                                        Code
                                    </th>
                                    <th className="category" style={{ maxWidth: '15%', cursor: 'pointer' }} onClick={() => this.onSortTickets('category.name')}>
                                        Catégorie
                                    </th>
                                    <th className="com" style={{ maxWidth: '5%' }} title="Commentaires">
                                        C
                                    </th>
                                    <th className="deadline" style={{ cursor: 'pointer' }} onClick={() => this.onSortTickets('deadline')}>
                                        Priorité
                                    </th>
                                    <th className="status" style={{ maxWidth: '10%', cursor: 'pointer' }} onClick={() => this.onSortTickets('status.id.order')}>
                                        Statut
                                    </th>
                                    <th className="updatedAt" style={{ maxWidth: '5%', cursor: 'pointer' }} onClick={() => this.onSortTickets('updatedAt')}>
                                        Màj
                                    </th>
                                    <th className="title" style={{ minWidth: '200px', cursor: 'pointer' }} onClick={() => this.onSortTickets('title')}>
                                        Objet
                                    </th>
                                    <th className="follower" style={{ cursor: 'pointer' }} onClick={() => this.onSortTickets('user.name')}>
                                        Profil suivi
                                    </th>
                                    <th className="assignTo" style={{ cursor: 'pointer' }} onClick={() => this.onSortTickets('assignTo.user.name')}>
                                        Profil affecté
                                    </th>
                                    <th className="lastUpdate" style={{ maxWidth: '10%' }}>
                                        Dernière Modification
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tickets.map(ticket => {
                                        const assignToUsers = [];
                                        ticket.assignTo.user.map(u => assignToUsers.push(u.name));
                                        return (
                                            <tr key={`ticket_${ticket._id}`} onClick={() => this.editTicket(ticket._id)}>
                                                <td className="code" style={{textAlign: "center"}}>{ticket.code}</td>
                                                <td className="category">{ticket.category}</td>
                                                <td className="com" style={{textAlign: "center"}}>{ticket.nbr_notes}</td>
                                                <td className="deadline">{ticket.priority === 'Date butoir' ? this.formatDate(ticket.deadline) : ticket.priority }</td>
                                                <td className="status">
                                                    <span className={`ticket_status_${status.findIndex(s => s.name === ticket.status)}`} style={{ color: 'black', padding: '2px' }}>{ticket.status}</span>
                                                </td>
                                                <td className="updatedAt" style={{textAlign: "center"}}>{this.formatDate(ticket.updatedAt)}</td>
                                                <td className="title">{ticket.title}</td>
                                                <td className="follower">{ticket.user.name}</td>
                                                <td className="assignTo">{assignToUsers.join(', ')}</td>
                                                <td className="lastUpdate">{ticket.history[ticket.history.length - 1].user.name}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="apm_paging" hidden={count <= itemsPerPages}>
                            <ReactPagination
                                hideDisabled
                                activePage={query.page}
                                itemsCountPerPage={itemsPerPages}
                                totalItemsCount={count}
                                pageRangeDisplayed={10}
                                onChange={(page) => this.onChangePage(page)}
                                activeClass="current"
                                prevPageText={<span>&lt;</span>}
                                nextPageText={<span>&gt;</span>}
                                firstPageText={<span>&lt;&lt;</span>}
                                lastPageText={<span>&gt;&gt;</span>}
                            />
                        </div>
                    </div>
                    <div className="apm_filters_container">
                        <h2 className="apm_filters_title">FILTRES</h2>
                        <form onSubmit={this.filterTickets}>
                            <div className="form-box fts">
                                <label>Rechercher</label>
                                <small><i>(code, titre, description, catégorie, profils)</i></small>
                                <div>
                                    <input type="text" name="search" className="input-box" value={query.q} onChange={this.onChangeSearch} />
                                </div>
                            </div>
                            <div className="form-box priority">
                                <label>Priorité</label>
                                <div>
                                    <label>
                                        <input id="priority" name="priority" type="checkbox" className="input-box" checked={query.priority} onChange={this.onChangePriority} /> Immédiat
                                    </label>
                                    <br/>
                                    <input id="deadline" name="deadline" type="date" className="input-box" value={query.deadline} onChange={this.onChangeDeadline} />
                                </div>
                            </div>
                            <div>
                                <button type="submit">Filtrer</button> <button type="button" onClick={this.reset}>Réinitialiser par défaut</button>
                            </div>
                        </form>
                    </div>
                </div>
                <style>{`
                    .apm-toast { width: 300px; padding: 10px; z-index: 9999; position: fixed; top: 20px; right: 20px; border-radius: 5px; }
                    .apm-toast p { color: #fff; font-size: 16px; margin: 0; }

                    .apm_container { display: flex; }
                    .apm_container button { padding: 10px; border: 1px solid; outline: none; cursor: pointer; }
                    .apm_container button + button { margin-left: 10px; }
                    
                    .apm_list_container { width: 85%; }
                    .apm_list_container h2 { text-transform: uppercase; }
                    .apm_list_container .apm_top { display: flex; justify-content: space-between; }
                    .apm_list_container .apm_filter_types button { margin: 0; padding: 10px; background-color: #ccc; border: none; outline: none; border-bottom: 1px solid; cursor: pointer; border-radius: 5px 5px 0 0; }
                    .apm_list_container .apm_filter_types button.active { background-color: white; border-top: 1px solid; border-left: 1px solid; border-right: 1px solid; border-bottom: none; }

                    .apm_list_container .apm_filter_status { display: flex; justify-content: flex-end; }
                    .apm_list_container .apm_filter_status label { margin-left: 10px; padding: 3px; font-weight: bold; color: black; }

                    .apm_list_container .apm_tickets_list { border-collapse: collapse; border-spacing: 0; width: 100%; }
                    .apm_list_container .apm_tickets_list thead tr th { color: black; background-color: #cccccc; height: 30px; }
                    .apm_list_container .apm_tickets_list td, .apm_tickets_list th { border: 1px solid #ffffff; padding: 5px 8px; }
                    .apm_list_container .apm_tickets_list tbody tr { cursor: pointer; }
                    .apm_list_container .apm_tickets_list tbody tr:nth-child(odd) td { background: #ececec; }

                    .apm_list_container .apm_paging { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
                    .apm_list_container .apm_paging ul { display: flex; justify-content: center; list-style-type: none; margin-bottom: 0; }
                    .apm_list_container .apm_paging li { position: relative; font-weight: 300; line-height: 1.35; color: #9B9B9B; }
                    .apm_list_container .apm_paging li + li:before { display: inline-block; content: '|'; margin: 0 3px; }
                    .apm_list_container .apm_paging a { color: inherit; text-decoration: none; padding: 10px; }
                    .apm_list_container .apm_paging .paging__spacer { padding: 0 5px; }
                    .apm_list_container .apm_paging .current a { color: #4A4A4A; font-weight: bold; }

                    .apm_filters_container { width: 300px; margin-left: 20px; padding: 10px; border-left: 1px solid; }
                    .apm_filters_container .apm_filters_title { text-align: center; }
                    .apm_filters_container .form-box { margin-bottom: 10px; }
                    .apm_filters_container .form-box label { display: block; font-weight: bold; }
                    .apm_filters_container .form-box input[type=text],
                    .apm_filters_container .form-box input[type=date] { width: 100%; }
                `}</style>
            </>
        )
    }
}

export default ModuleAPMList;